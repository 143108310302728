import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CInfinitySlider,
  COverflowTable,
  CSimpleCard,
  CTable,
  CBtnList,
  CInlineLinkList,
  LVenue,
  CFixedImg,
  CSectTitle03,
  LBanquetContact,
} from '../../../../components/_index';
// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const [modalFlag, setModalFlag] = useState(false);
  const openModal = (e: any) => {
    e.stopPropagation();
    setModalFlag(!modalFlag);
  };
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        type='ja'
        data={{
          title: {
            main: 'セミナー・懇親会',
            sub: (
              <>
                SEMINAR
              </>
            ),
          },
          imgList: [
            {
              img: {
                src: '/assets/images/banquet/plan/seminar/kv.png',
              },
              imgSp: {
                src: '/assets/images/banquet/plan/seminar/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <section className='l_sect02 u_pt70'>
        <LWrap>
          <h2 className="c_headingLv2 u_tac u_mb10">
            プロフェッショナルが提供する<br className='u_sp' />最高のホスピタリティで、上質な学びや交流を
          </h2>
          <p className="c_sectLead u_mb60">
            ロイヤルパークホテルでは各種セミナーや懇親会にご対応をしております。
          </p>
          <CSectTitle03 exClass='u_mb30' title="セミナー" />
          <p className='c_sectLead u_mb70'>
            社内会議やセミナーなど各種イベントにお使いいただける大小さまざまな宴会場をご用意しております。<br />
            ホテルならではの上質なサービスと特別な雰囲気を提供し、皆様のお手伝いをいたします。<br />
            また、経験豊富なスタッフがお客様のご要望をお伺いし、会議やセミナーの成功に向けて徹底したサポートをお約束いたします。
          </p>
          <CSectTitle03 exClass='u_mb30' title="懇親会" />
          <p className='c_sectLead'>
            フランス料理、中国料理、日本料理などの洗練されたメニューを、シェフが心を込めてお作りします。<br />
            繊細なコース料理やバラエティ豊かな立食ブッフェ、落ち着いた雰囲気の中での着席ブッフェなど、<br />
            お客様のご要望に合わせて、多種多様なスタイルをご提案いたします。<br />
            美食と上質な空間で、参加者の皆様が一体感を味わいながら、大切な交流の時間をお過ごしいただけます。
          </p>
        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [
            {
              label: '宴会・会議',
              path: '/banquet/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LBanquetContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
